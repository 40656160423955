/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { Fragment, useEffect, useRef } from 'react';
import Link from 'next/link';
import { isEmpty } from 'global/utils/isEmpty.utils';
import { Menu, Transition, Popover } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import useGetHeaderHeight from 'hooks/useGetHeaderHeight';
import {
  getNotificationList,
  getNotificationCount,
  readAllNotifications,
  readNotificationById,
} from 'api/notification';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { USER_ROLE } from 'constants/index';
import { useAuth } from 'utils/context/auth';
import moment from 'moment';
import { redirectWithOriginUrl } from 'global/utils/router.utils';
import { Icon } from '@iconify/react';

const timeoutDuration = 120;

const NotificationMenu = () => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const {
    user: {
      auth: { role },
    },
  } = useAuth();
  const headerHeight = useGetHeaderHeight();
  const triggerRef = useRef();
  const timeOutRef = useRef();

  const handleEnter = (isOpen) => {
    clearTimeout(timeOutRef.current);
    !isOpen && triggerRef.current?.click();
  };

  const handleLeave = (isOpen) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && triggerRef.current?.click();
    }, timeoutDuration);
  };

  const notificationCount = useQuery('getNotificationCount', getNotificationCount, {
    refetchOnWindowFocus: true,
    retry: false,
  });
  const notificationsDatas = useQuery('getNotificationList', getNotificationList, { retry: 1 });
  const notificationsData = notificationsDatas?.data || [];

  const readAllNotif = useMutation(readAllNotifications, {
    onError: (error, variables, context) => {},
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['getNotificationList']);
      queryClient.invalidateQueries(['getNotificationCount']);
    },
  });
  const readNotifById = useMutation(readNotificationById, {
    onError: (error, variables, context) => {},
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['getNotificationList']);
      queryClient.invalidateQueries(['getNotificationCount']);
    },
  });

  const readAllNotificationsAction = () => {
    readAllNotif.mutate();
  };

  const readNotificationsByIdAction = (id) => {
    readNotifById.mutate(id);
  };

  const mappingEntityType = (entityType) => {
    switch (entityType) {
      case 'PURCHASE_ORDER':
        return 'transaction-list';
      case 'PURCHASE_REQUEST':
        return 'transaction-request';
      default:
        return 'transaction-list';
    }
  };

  const handleClick = (e, item) => {
    e.preventDefault();
    readNotificationsByIdAction(item?.uid);

    if (item?.entityType === 'QUOTATION') router.push(`/dashboard/rfq/${item?.entityId}`);

    /* Customer */
    if (item?.userType === 'CUSTOMER') {
      redirectWithOriginUrl(router, `/dashboard/customer/transaction-list/${item?.entityId}`);
    }

    /* Staff, Manager, & Parent */
    if (item?.userType !== 'CUSTOMER') {
      // Reward
      if (item?.entityType === 'REWARD') {
        if (item?.entityId) {
          router.push(`/dashboard/reward/transaction/${item?.entityId}`);
        } else {
          router.push('/dashboard/reward');
        }
      }

      // PR & PO
      if (item?.entityType === 'PURCHASE_ORDER' || item?.entityType === 'PURCHASE_REQUEST') {
        router.push(
          // eslint-disable-next-line no-nested-ternary
          `/dashboard/${
            role === USER_ROLE.BUYER_MANAGER
              ? 'manager'
              : role === USER_ROLE.BUYER_STAFF
                ? 'staff'
                : 'parent'
          }/${mappingEntityType(item?.entityType)}/${item?.entityId}`,
        );
      }
    }
  };

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <div onMouseEnter={() => handleEnter(open)} onMouseLeave={() => handleLeave(open)}>
            <Popover.Button
              className={`
            ${open ? '' : 'text-opacity-90'}
            group focus:outline-none group flex items-center cursor-pointer text-gray-800 text-base`}
              ref={triggerRef}
            >
              <div className="relative">
                <Icon icon="tdesign:notification" width="20" height="20" color="#555555" />
                {notificationCount?.data?.data !== 0 ? (
                  <span className="absolute top-[-12px] -right-[5px] flex justify-center items-center h-[18px] w-[18px] bg-theme-primary-main rounded-[20px] text-white text-[10px]">
                    {notificationCount?.data?.data}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Overlay
                className={clsx('fixed left-0 right-0 bottom-0 z-20 bg-black/[0.3]')}
                style={{ top: headerHeight }}
                onPointerDown={(e) => e.stopPropagation()}
              />
            </Transition>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="fixed lg:absolute left-0 right-0 lg:left-[-163px] xl:left-[-183px] z-20 mt-3">
                <div className="bg-white w-full lg:w-[350px]  xl:w-[388px] h-[457px] rounded-b-[10px] flex flex-col">
                  <div className="bg-white h-[42px] rounded-t-[5px] border-b-[1px] border-[#BBB] flex justify-between">
                    <p className="text-sm font-medium text-[#222222] px-4 py-2.5">Notifikasi</p>
                    <button
                      className="text-xs text-theme-primary-main pr-[15px]"
                      onClick={(e) => {
                        e.preventDefault();
                        readAllNotificationsAction();
                        return false;
                      }}
                    >
                      Tandai semua dibaca
                    </button>
                  </div>

                  <div className="bg-white h-full overflow-y-scroll custom-scrollbar divide-y divide-text-grayScale05">
                    {notificationsData.length <= 0 && (
                      <div className="p-4 text-sm">tidak ada notifikasi</div>
                    )}
                    {notificationsData?.map((item, idx) => (
                      <div className="relative min-h-[93px] px-[5px] py-[2.5px] border-none">
                        <div
                          className={`min-h-[93px] ${
                            item?.readDate == null ? 'bg-theme-primary-main bg-opacity-10' : ''
                          }  py-2.5 px-3.5 cursor-pointer rounded`}
                        >
                          <h3 className="text-[13px] text-[#222222] font-medium hover:text-red-500 flex justify-between">
                            <a onClick={(e) => handleClick(e, item)}>
                              <span className="absolute inset-0" aria-hidden="true" /> {item?.title}
                            </a>
                            <span className="text-[10px] font-[#666] font-normal">
                              {moment(item?.createdDate).format('DD-MM-YYYY HH:mm')}
                            </span>
                          </h3>
                          <p className="text-xs text-[#555555]">{item?.content}</p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="mt-auto bg-white h-[46px] border-t-[1px] border-[#BBB] flex justify-center py-3.5 px-2.5 rounded-b-[10px]">
                    <Link href="/dashboard/notifications">
                      <a className="text-xs text-[#444]">Lihat semua notifikasi</a>
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </>
  );
};

export default NotificationMenu;
