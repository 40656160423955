import React, { Fragment, useState, useEffect, forwardRef, useMemo } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import {
  BellIcon,
  InformationCircleIcon,
  ChevronDownIcon,
  ClipboardListIcon,
  UserIcon,
  ArchiveIcon,
  LogoutIcon,
  CogIcon,
} from '@heroicons/react/solid';
import Link from 'next/link';
import { useAuth } from 'utils/context/auth';
import { USER_ROLE, CONFIG_SITE, getNameFromRole } from 'constants/index';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { currencyFormatter } from 'global/utils/currency.utils';
import useGetHeaderHeight from 'hooks/useGetHeaderHeight';
import { Icon } from '@iconify/react';

const ProfileMenu = ({ isHomepage, remainingBalance, isShowInfo }) => {
  const visibilityBudgetInformation = useMemo(() => {
    if ([WHITELABEL_KEYS.idfood].includes(CONFIG_SITE?.WHITELABEL_KEY)) {
      return false;
    }

    return true;
  }, []);

  const parentMenus = [
    {
      id: 1,
      title: 'Akun Saya',
      url: '/dashboard/profile',
      icon: UserIcon,
    },
    {
      id: 2,
      title: 'Child Company',
      url: '/dashboard/parent/user-child',
      icon: ArchiveIcon,
    },
    {
      id: 3,
      title: 'Pesanan Saya',
      url: '/dashboard/parent/transaction-request',
      icon: ArchiveIcon,
    },
    {
      id: 4,
      title: 'Informasi',
      url: '/dashboard/parent/budget',
      icon: InformationCircleIcon,
      visible: visibilityBudgetInformation,
    },
  ];

  const managerMenus = [
    {
      id: 1,
      title: 'Akun Saya',
      url: '/dashboard/profile',
      icon: UserIcon,
    },
    {
      id: 2,
      title: 'Pesanan Saya',
      url: '/dashboard/manager/transaction-request',
      icon: ArchiveIcon,
    },
    // {
    //   id: 3,
    //   title: "Anggaran",
    //   url: "/dashboard/manager/budget",
    //   icon: CogIcon,
    // },
    // {
    //   id: 3,
    //   title: "Notifikasi Saya",
    //   url: "/dashboard/manager/notification",
    //   icon: BellIcon,
    // },
    {
      id: 4,
      title: 'Informasi',
      url: '/dashboard/manager/budget',
      icon: InformationCircleIcon,
      visible: visibilityBudgetInformation,
    },
  ];

  const staffMenus = [
    {
      id: 1,
      title: 'Akun Saya',
      url: '/dashboard/profile',
      icon: UserIcon,
    },
    {
      id: 2,
      title: 'Pesanan Saya',
      url: '/dashboard/staff/transaction-request',
      icon: ArchiveIcon,
    },
    // {
    //   id: 3,
    //   title: "Notifikasi Saya",
    //   url: "/dashboard/staff/notification",
    //   icon: BellIcon,
    // },
    {
      id: 4,
      title: 'Informasi',
      url: '/dashboard/staff/budget',
      icon: InformationCircleIcon,
      visible: visibilityBudgetInformation,
    },
  ];

  const customerMenus = [
    {
      id: 1,
      title: 'Akun Saya',
      url: '/dashboard/profile',
      icon: UserIcon,
    },
    {
      id: 2,
      title: 'Pesanan Saya',
      url: '/dashboard/customer/transaction-list',
      icon: ArchiveIcon,
    },
    // {
    //   id: 3,
    //   title: "Notifikasi Saya",
    //   url: "/dashboard/staff/notification",
    //   icon: BellIcon,
    // },
  ];

  const { user, logout } = useAuth();
  const [menus, setMenus] = useState([]);
  const [isBuyerStaff, setIsBuyerStaff] = useState(false);

  useEffect(() => {
    let menuUser;
    switch (user.auth.role) {
      case USER_ROLE.BUYER_ADMIN:
        menuUser = parentMenus;
        break;
      case USER_ROLE.BUYER_MANAGER:
        menuUser = managerMenus;
        break;
      case USER_ROLE.BUYER_STAFF:
        menuUser = staffMenus;
        setIsBuyerStaff(true);
        break;
      case USER_ROLE.CUSTOMER:
        menuUser = customerMenus;
        break;
      default:
        menuUser = [];
    }
    setMenus(menuUser);
  }, [user.auth.role]);

  const headerHeight = useGetHeaderHeight();

  return (
    <div className="z-20 w-full">
      <span className="w-[1.2px] bg-lightGray h-10" />
      <Menu as="div" className="relative">
        {({ open }) => (
          <>
            <div className="flex flex-row items-center gap-2 justify-end">
              <>
                <Menu.Button className="flex flex-row items-center">
                  <div className="flex flex-col mr-2 text-left w-[100px]">
                    <p className="text-sm text-[#1A1A1A] font-semibold text-ellipsis overflow-hidden line-clamp-1 text-right">
                      {user.data.fullName}
                    </p>
                    <p className="text-xs text-[#666666] font-semibold text-right">
                      {getNameFromRole(user.auth.role)}
                    </p>
                  </div>
                  <div className="w-10 h-10 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 flex items-center justify-center">
                    {user.data.avatar ? (
                      <img
                        className="w-full h-full object-center object-cover rounded-full"
                        src={user.data.avatar}
                        alt="ava name"
                      />
                    ) : (
                      <Icon
                        icon="mingcute:user-4-fill"
                        width="full"
                        height="full"
                        style={{ color: '#55555555' }}
                      />
                    )}
                  </div>
                </Menu.Button>
                {open && (
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div
                      className={clsx('fixed left-0 right-0 bottom-0 z-20 bg-black/[0.3]')}
                      style={{ top: headerHeight }}
                      onPointerDown={(e) => e.stopPropagation()}
                    />
                  </Transition>
                )}
              </>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-[300px] rounded-[15px] shadow-lg px-[30px] py-7 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                {isBuyerStaff && visibilityBudgetInformation && (
                  <Menu.Item className="relative px-4 py-5 mb-5 space-x-4 items-center flex flex-row drop-shadow-md bg-white rounded-lg overflow-hidden">
                    <div className="flex">
                      <div className="flex w-10 h-10 relative">
                        <Image
                          src="/assets/icons/icon-budget-red.svg"
                          alt="Icon Budget"
                          width="25px"
                          height="25px"
                          layout="fill"
                        />
                      </div>
                      <div className="flex flex-col">
                        <p className="mb-0.5 text-grayScale04 text-xs font-normal">Sisa Anggaran</p>
                        <p className="text-theme-primary-main text-base font-semibold">
                          Rp. {currencyFormatter(remainingBalance)}
                        </p>
                      </div>
                      <div className="absolute top-0 -right-4 flex w-[220px] h-[100px] ">
                        <Image
                          src="/assets/illustrations/accent-bg-red.svg"
                          alt="accent red"
                          width="50px"
                          height="500px"
                          layout="fill"
                        />
                      </div>
                    </div>
                  </Menu.Item>
                )}
                {menus?.map((menu) => (
                  <Menu.Item key={menu.title}>
                    {({ active }) => (
                      <Link href={menu.url}>
                        <a
                          className={clsx(
                            menu?.visible ?? true ? 'flex' : 'hidden',
                            active ? 'bg-gray-100' : '',
                            'group relative flex-row items-center py-3 font-semibold text-base text-grayScale05 border-b group-hover:text-theme-primary-main hover:text-theme-primary-main',
                          )}
                        >
                          <menu.icon
                            width={20}
                            height={20}
                            className="relative text-grayScale05 mr-3 group-hover:text-theme-primary-main"
                          />
                          {menu.title}
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                ))}
                <Menu.Item>
                  <p
                    onClick={() => {
                      logout();
                    }}
                    className="group flex flex-row items-center py-3 font-semibold text-base text-grayScale05 group-hover:text-theme-primary-main hover:text-theme-primary-main cursor-pointer"
                  >
                    <LogoutIcon
                      width={20}
                      height={20}
                      className="relative text-grayScale05 mr-3 group-hover:text-theme-primary-main hover:text-theme-primary-main"
                    />
                    Log out
                  </p>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default ProfileMenu;
